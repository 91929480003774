import { TFunction } from 'react-i18next';
import { EComponentTypes } from '../definitions';
import { ContentComponent } from '../types/objects';

const COMPONENT_TYPE = {
  [EComponentTypes.TEXT]: 'common:content_components_text',
  [EComponentTypes.EXTERNAL_VIDEO]: 'common:content_components_external_video',
  [EComponentTypes.VIDEO]: 'common:content_components_video',
  [EComponentTypes.IMAGE]: 'common:content_components_image',
  [EComponentTypes.PDF]: 'common:content_components_pdf',
} as const;

type ComponentListType = {
  [key in ContentComponent['type']]: number;
};

export const getComponentListDescription = (components: ContentComponent[], t: TFunction) => {
  const init = {} as ComponentListType;
  const contentTypes = components.reduce<ComponentListType>((result, { type }) => ({
    ...result,
    [type]: result[type] ? result[type] + 1 : 1,
  }), init);

  const types = Object.keys(contentTypes) as Array<keyof ComponentListType>;
  return types.map((type) => {
    return t(COMPONENT_TYPE[type], { count: contentTypes[type] });
  }).join(', ');
};

export const QUESTION_FORM_PREFIX = 'question-';
