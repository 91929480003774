import { combineReducers } from 'redux';
import * as R from 'ramda';
import * as reducerUtil from '../../../common/utils/reducer';
import {
  ACADEMY_RECEIVE_MODULE,
  ACADEMY_UPDATE_MODULE,
  ACADEMY_ADD_SCREEN,
  ACADEMY_ADD_MODULE,
  ACADEMY_REMOVE_SCREEN,
  ACADEMY_REMOVE_MODULE,
  ACADEMY_UPDATE_SCREEN,
  LEARNING_FETCH_COURSE,
  LEARNING_DUPLICATE_MODULE,
} from '../actions';
import * as usersReducer from '../../core/reducers/users';
import * as screensReducer from './screens';

const addToStore = R.curry((acc, course) => R.assoc(course.id, {
  ...acc[course.id],
  ...course,
}, acc));

const items = (state = {}, action) => {
  switch (action.type) {
    case LEARNING_FETCH_COURSE:
      return R.reduce(addToStore, state, action.modules);
    case LEARNING_DUPLICATE_MODULE:
      const mods = action.section.modules.map((mod) => {
        return {
          ...mod,
          screen_count: mod?.screens?.length || 0
        };
      });
      return R.reduce(addToStore, state, mods);
    case ACADEMY_RECEIVE_MODULE:
    case ACADEMY_UPDATE_MODULE:
      return addToStore(state, action.item);
    case ACADEMY_ADD_MODULE:
      return addToStore(R.omit([action.tempId], state), action.item);
    case ACADEMY_ADD_SCREEN: {
      return reducerUtil.update(action.moduleId, (course) => ({
        ...course,
        screen_ids: [...(course.screen_ids || []), action.screen.id],
      }), state);
    }
    case ACADEMY_REMOVE_MODULE:
      return R.omit([action.courseId], state);
    case ACADEMY_REMOVE_SCREEN:
      return reducerUtil.update(action.moduleId, (course) => ({
        ...course,
        screen_ids: R.reject(R.equals(action.id), course.screen_ids || []),
      }), state);
    // Update selector
    case ACADEMY_UPDATE_SCREEN: {
      if (!state[action.module_id]) return state;

      const course = R.clone(state[action.module_id]);

      if (action.shouldIncrementScreenCount) course.screen_count += 1;
      if (action.shouldDecrementScreenCount) course.screen_count -= 1;

      return reducerUtil.update(action.module_id, R.always(course), state);
    }
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const course = state.academy.modules.items[id];

  // console.log("debug modulesReducer findById course", course);

  if (!course) return undefined;

  const result = {
    ...course,
    user: usersReducer.findById(state, course.created_by),
    screens: R.pipe(
      R.map(screensReducer.findById(state)),
      R.reject(R.isNil),
      R.sortBy(R.prop('index')),
    )(course.screen_ids || []),
  };

  // console.log("debug modulesReducer findById result", result);

  return result;
});

export default combineReducers({ items });
