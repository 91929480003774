import Pusher from 'pusher-js';
import Api from './api';

const transformChannelName = (name, state) => {
  if (name === 'me') return `private-user-${state.loggedUser.user.id}`;
  return name;
};

export default (() => {
  let _store;
  let _socket;
  let _events = {};

  const connectEvent = (event) => {
    let channel = _socket.channels.channels[event.channelName];

    if (_socket && event.channelName && !channel) {
      channel = _socket.subscribe(event.channelName);

      // Development logging
      if (process.env.NODE_ENV === 'development') {
        channel.bind('pusher:subscription_succeeded', () => console.log(`Pusher : Subscribed to ${event.channelName}`));
        channel.bind('pusher:subscription_error', (error) => console.log(`Pusher : failed ${event.channelName}`, error));
      }
    }

    if (_events[event.eventName]) {
      channel.unbind(event.eventName);
    }

    _events[event.eventName] = event;

    return channel.bind(event.eventName, (payload) => {
      Object.values(event.action).forEach((action) => action(payload));
    });
  };

  const getEvent = (channelName, eventName, action, scope) => {
    if (_socket.channels.channels[channelName] && _events[eventName]) {
      return {
        ..._events[eventName],
        action: {
          ..._events[eventName].action,
          [scope]: action,
        },
      };
    }
    return {
      eventName,
      channelName,
      action: { [scope]: action },
    };
  };

  return {
    getPusher: () => _socket,
    connect: (store) => (_store = store),
    initialise: async () => {
      if (_socket) return false;

      if (process.env.NODE_ENV === 'development') Pusher.logToConsole = true;

      // Create connection
      _socket = new Pusher(process.env.PUSHER_KEY, {
        cluster: 'eu',
        encrypted: true,
        authorizer: (channel) => ({
          authorize: async (socketId, callback) => {
            try {
              const response = await Api.post('/v2/pusher/auth', {
                socket_id: socketId,
                channel_name: channel.name,
              });

              callback(null, { auth: response.auth });
            } catch (err) {
              callback(true, err.status);
            }
          },
        }),
      });

      // If running on production, add extra logging
      if (process.env.NODE_ENV === 'production' && window.Raven) {
        window.Raven.setExtraContext({ config: _socket.config });
        _socket.connection.bind('failed', (e) => window.Raven.captureException(new Error(e)));
      }

      return true;
    },
    registerEvent: (channel, eventName, action, scope = 'global') => {
      if (!_socket || !_store) return false;
      const channelName = transformChannelName(channel, _store.getState());
      const event = getEvent(channelName, eventName, action, scope);
      connectEvent(event);
      return action;
    },
    disconnect: () => {
      if (_socket) _socket.disconnect();
      _socket = undefined;
      _events = {};
    },
    getChannels: () => _socket.channels.channels,
  };
})();
