import { combineReducers } from 'redux';
import * as R from 'ramda';
import {
  SURVEYS_RECEIVE_SURVEY,
  SURVEYS_UPDATE_SCREEN,
  SURVEYS_CREATE_QUESTION,
} from '../../survey/actions';
import {
  ACADEMY_RECEIVE_MODULE,
  ACADEMY_UPDATE_SCREEN,
  ACADEMY_ADD_SCREEN,
} from '../actions';
import { QUESTION_FORM_PREFIX } from '../utils/get-component-list';

const addToStore = R.curry((state, component) => R.assoc(component.id, component, state));
export const addScreenComponentsToStore = (state, screens) => {
  return R.reduce(
    (acc, screen) => R.reduce(addToStore, acc, screen.components),
    state,
    screens
  );
};

const items = (state = {}, action) => {
  switch (action.type) {
    case ACADEMY_RECEIVE_MODULE:
    case SURVEYS_RECEIVE_SURVEY:
    case 'forms/RECEIVE_FORM':
    case 'forms/CREATE_FORM':
    case 'forms/DUPLICATE_FORM':
      return addScreenComponentsToStore(state, action.item.screens);
    case ACADEMY_UPDATE_SCREEN:
    case SURVEYS_UPDATE_SCREEN:
    case SURVEYS_CREATE_QUESTION:
    case ACADEMY_ADD_SCREEN:
    case 'forms/CREATE_QUESTION':
    case 'forms/DUPLICATE_SCREEN':
    case 'forms/SAVE_DRAFT':
      return R.reduce(addToStore, state, action.screen.components);

    // Update the question text in real-time as the user types in the editor.
    // Ticket : ONE-711 : https://linear.app/oneteam/issue/ONE-711/question-state-for-form-and-surveys-does-not-update-in-realtime
    case '@@redux-form/CHANGE':
      // Look for changes to the question text field. If found, update the state.
      // The field name is in the format 'question-<componentId>'.
      if (
        action.meta &&
        R.test(new RegExp(`^${QUESTION_FORM_PREFIX}`), action.meta.form) &&
        action.meta.field === 'parameters.text'
      ) {
        const componentId = action.meta.form.split('-')[1];
        return R.assocPath([componentId, 'parameters', 'text'], action.payload, state);
      }
      return state;
    default: return state;
  }
};

export const findById = R.curry((state, id) => state.academy.components.items[id]);

export default combineReducers({ items });
