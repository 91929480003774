import * as R from 'ramda';
import { ThunkAction } from 'redux-thunk';
import Api from '@services/api';
import Storage from '@services/storage';

import type { StoreState } from '@common/types/store';
import type {
  AccessDataNetwork,
  AccessDataFunction,
  AccessData,
} from '../types';
import { Dispatch } from 'redux';

export const AUTHENTICATION_LOGIN = 'auth/LOGIN';
export const AUTHENTICATION_FAILED = 'auth/FAILED';
export const LOGOUT_USER = 'core/LOGOUT_USER';
export const AUTHENTICATION_ONBOARD_ORGANISATION = 'auth/ONBOARD_ORGANISATION';

export const authenticate = (
  credentials: { username: string, password: string, remember_me: boolean },
) => async (dispatch: Dispatch) => {
  try {
    const { data } = await Api.post('/v2/authenticate', {
      username: credentials.username,
      password: credentials.password,
    }, false);

    // @ts-expect-error
    const { access_token: accessToken, refresh_token: refreshToken } = data;

    const expiration = credentials.remember_me ? [10, 'years'] : undefined;

    // Store tokens
    Storage.setCookie('access_token', accessToken, [59, 'minutes']);
    Storage.setCookie('refresh_token', refreshToken, expiration);
    Storage.setCookie('auth_token', refreshToken, expiration);
    Storage.setCookie('original_domain', window.location.host, expiration);

    return dispatch({
      type: AUTHENTICATION_LOGIN,
      // @ts-expect-error
      lastLogin: data.last_login,
      // @ts-expect-error
      temporaryPassword: data.temporary_password,
      // user,
    });
  } catch (err) {
    dispatch({ type: AUTHENTICATION_FAILED });

    throw err;
  }
};

export const fetchOrganisations = () => async (dispatch: Dispatch) => {
  const response = await Api.get('/v1/organisations');

  dispatch({
    type: 'auth/RECEIVE_ORGANISATIONS',
    items: response.data,
  });

  return response;
};

// @ts-expect-error
export const setupTrial = (organisationId, values) => async (dispatch) => {
  const payload = R.pick(['name', 'theme', 'company_size', 'industry'], values);

  const promises = [Api.post(`/v3/organisations/${organisationId}/setup-trial`, payload)];

  if (values.brand_icon) {
    const fd = new FormData();
    fd.append('brand_icon', values.brand_icon);

    promises.push(Api.post(`/v2/organisations/${organisationId}/picture`, fd));
  }

  const [{ data: organisation }, picture] = await Promise.all(promises);

  if (picture) {
    // @ts-expect-error
    organisation.brand_icon = `${picture.data.brand_icon}?update=${new Date().getTime()}`;
  }

  return dispatch({
    type: AUTHENTICATION_ONBOARD_ORGANISATION,
    organisation,
  });
};

export const createTrial = (email: string) => Api.post('/v3/create-trial', { email }, false);

type ActualGetAccessData = ThunkAction<
Promise<AccessData>,
StoreState,
unknown,
any
>;

type AccessDataApiResponse = {
  data: {
    organisation_name: string;
    organisation_brand_icon: string;
    organisation_theme: {
      login_image_url: string | null;
    };
    functions: AccessDataFunction[];
    networks: AccessDataNetwork[];
  };
};

export const getAccessData = (id: string): ActualGetAccessData => async () => {
  const { data } = await Api.get<AccessDataApiResponse>(`/v1/access-requests/prefetch?access_request_code=${id}`, false);

  return {
    networks: data.networks,
    functions: data.functions,
    name: data.organisation_name,
    brand_icon: data.organisation_brand_icon,
    theme: data.organisation_theme,
  };
};

export type RequestValues = {
  first_name: string;
  last_name: string;
  email: string;
  network_id: string;
  function_id: string;
};

export const createAccessRequest = (id: string, values: RequestValues) => async () => {
  const { is_new: isNew } = await Api.post<{ is_new: boolean }>(`/v1/access-requests?access_request_code=${id}`, values, false);

  return isNew;
};
