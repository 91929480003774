import app from './app';
import networks from './networks';
import loggedUser from './logged-user';
import users from './users';
import notifications from './notifications';
import permissions from './permissions';
import ui from './ui';
import language from './language';
import activities from './activities';
import { CORE_DISMISS } from '../actions';

const dismissed = (state = {}, action) => {
  switch (action.type) {
    case CORE_DISMISS:
      return {
        ...state,
        [action.name]: true,
      };
    default:
      return state;
  }
};

export default {
  activities,
  app,
  networks,
  loggedUser,
  users,
  notifications,
  ui,
  permissions,
  language,
  dismissed,
};
